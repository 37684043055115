exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-pages-faq-recherche-jsx": () => import("./../../../src/pages/pages/faq/recherche.jsx" /* webpackChunkName: "component---src-pages-pages-faq-recherche-jsx" */),
  "component---src-pages-pages-payment-jsx": () => import("./../../../src/pages/pages/payment.jsx" /* webpackChunkName: "component---src-pages-pages-payment-jsx" */),
  "component---src-pages-pages-payment-loading-axepta-jsx": () => import("./../../../src/pages/pages/paymentLoading/axepta.jsx" /* webpackChunkName: "component---src-pages-pages-payment-loading-axepta-jsx" */),
  "component---src-pages-pages-reservation-jsx": () => import("./../../../src/pages/pages/reservation.jsx" /* webpackChunkName: "component---src-pages-pages-reservation-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-templates-city-jsx": () => import("./../../../src/templates/city.jsx" /* webpackChunkName: "component---src-templates-city-jsx" */),
  "component---src-templates-country-jsx": () => import("./../../../src/templates/country.jsx" /* webpackChunkName: "component---src-templates-country-jsx" */),
  "component---src-templates-faq-category-jsx": () => import("./../../../src/templates/faqCategory.jsx" /* webpackChunkName: "component---src-templates-faq-category-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-faq-response-jsx": () => import("./../../../src/templates/faqResponse.jsx" /* webpackChunkName: "component---src-templates-faq-response-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-payment-jsx": () => import("./../../../src/templates/payment.jsx" /* webpackChunkName: "component---src-templates-payment-jsx" */)
}

